"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const ml_matrix_1 = require("ml-matrix");
//ts-ignore
const rankMatrix = require('./rankMatrix.js');
const buildBlankMatrix = function (row, col) {
    return [...Array(row)].map((row, rowIndex) => [...Array(col)].map((col, colIndex) => {
        return {
            value: undefined, x: rowIndex, y: colIndex
        };
    }));
};
const random = (min, max) => Math.floor(Math.random() * (max + 1 - min)) + min;
const dataToMatrix = function (data) {
    try {
        return data.map((row) => row.map((col) => {
            if (ng.isNumber(col.value))
                return col.value;
            else
                throw 'Value is not number';
        }));
    }
    catch (_a) {
        return undefined;
    }
};
class MatrixcalculatorCtrl {
    constructor($scope, $timeout, $filter, ConfigService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.sizes = [...Array(9)].map((item, index) => index + 1);
        this.operation = JSON.parse(localStorage.getItem(`${this.constructor.name}_operation`) || 'null') || 'add';
        this.operations = {
            'add': {
                title: this.$filter('translate')('Addition'),
                symbol: 'plus'
            },
            'sub': {
                title: this.$filter('translate')('Subtraction'),
                symbol: 'minus'
            },
            'mul': {
                title: this.$filter('translate')('Multiplication'),
                symbol: 'xmark'
            },
            'mul_by_number': {
                title: this.$filter('translate')('Scalar multiplication'),
                symbol: 'xmark'
            },
            'div': {
                title: this.$filter('translate')('Division'),
                symbol: 'divide'
            },
            'trans': {
                title: this.$filter('translate')('Transpose')
            },
            'inv': {
                title: this.$filter('translate')('Inversion')
            },
            'det': {
                title: this.$filter('translate')('Determinant')
            },
            'rank': {
                title: this.$filter('translate')('Rank')
            },
            'power': {
                title: this.$filter('translate')('Exponentiation')
            },
        };
        this.matrixA = {
            row: 3,
            col: 3,
            data: buildBlankMatrix(3, 3),
        };
        this.matrixB = {
            row: 3,
            col: 3,
            data: buildBlankMatrix(3, 3),
        };
    }
    setOperation(operation) {
        this.operation = operation;
        localStorage.setItem(`${this.constructor.name}_operation`, JSON.stringify(this.operation));
        this.result = undefined;
        this.changeSizeA(this.matrixA.row, this.matrixA.col);
    }
    $onInit() {
    }
    submitForm($form) {
        this.result = undefined;
        if ($form.$valid) {
            const _mA = dataToMatrix(this.matrixA.data);
            const _mB = dataToMatrix(this.matrixB.data);
            if (_mA && _mB && !this.result) {
                const matrixA = new ml_matrix_1.Matrix(_mA);
                const matrixB = new ml_matrix_1.Matrix(_mB);
                switch (this.operation) {
                    case 'add': {
                        const matrixC = ml_matrix_1.Matrix.add(matrixA, matrixB);
                        this.result = {
                            data: matrixC.data,
                            matrix: true,
                            tex: `\\[A+B=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                        };
                        break;
                    }
                    case "sub": {
                        const matrixC = ml_matrix_1.Matrix.sub(matrixA, matrixB);
                        this.result = {
                            data: matrixC.data,
                            matrix: true,
                            tex: `\\[A-B=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                        };
                        break;
                    }
                    case "mul": {
                        const matrixC = matrixA.mmul(matrixB);
                        this.result = {
                            data: matrixC.data,
                            matrix: true,
                            tex: `\\[A*B=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                        };
                        break;
                    }
                    case "div": {
                        const matrixC = ml_matrix_1.Matrix.div(matrixA, matrixB);
                        this.result = {
                            data: matrixC.data,
                            matrix: true,
                            tex: `\\[A/B=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                        };
                        break;
                    }
                }
            }
            if (_mA && !this.result) {
                const matrixA = new ml_matrix_1.Matrix(_mA);
                switch (this.operation) {
                    case "mul_by_number": {
                        if (this.b) {
                            const matrixC = ml_matrix_1.Matrix.mul(matrixA, this.b);
                            this.result = {
                                data: matrixC.data,
                                matrix: true,
                                tex: `\\[A*${this.b}=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                            };
                        }
                        break;
                    }
                    case 'trans': {
                        const matrixC = matrixA.transpose();
                        this.result = {
                            data: matrixC.data,
                            matrix: true,
                            tex: `\\[A^T=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                        };
                        break;
                    }
                    case "inv": {
                        const matrixC = (0, ml_matrix_1.inverse)(matrixA);
                        this.result = {
                            data: matrixC.data,
                            matrix: true,
                            tex: `\\[A^{-1}=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                        };
                        break;
                    }
                    case "det": {
                        if (this.matrixA.row == this.matrixA.col) {
                            const det = (0, ml_matrix_1.determinant)(matrixA);
                            this.result = {
                                data: det,
                                //@ts-ignore
                                tex: `\\[\\begin{vmatrix}${matrixA.data.map((row) => row.join(' & ')).join('\\\\')}\\end{vmatrix}=${det}\\]`
                            };
                        }
                        break;
                    }
                    case "rank": {
                        //@ts-ignore
                        const rank = rankMatrix.rankOfMatrix(matrixA.data);
                        this.result = {
                            data: rank,
                            //@ts-ignore
                            tex: `\\[rank\\begin{pmatrix}${matrixA.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}=${rank}\\]`
                        };
                        break;
                    }
                    case "power": {
                        if (this.b) {
                            const b = parseInt(this.b.toFixed(0));
                            if (b >= 1) {
                                let steps = b - 1;
                                let matrixC = matrixA;
                                while (steps > 0) {
                                    matrixC = matrixC.mmul(matrixA);
                                    steps -= 1;
                                }
                                this.result = {
                                    data: matrixC.data,
                                    matrix: true,
                                    //@ts-ignore
                                    tex: `\\[A^2=\\begin{pmatrix}${matrixC.data.map((row) => row.join(' & ')).join('\\\\')}\\end{pmatrix}\\]`
                                };
                            }
                        }
                        break;
                    }
                }
            }
            const code = () => {
                return document.querySelectorAll('.mathjax');
            };
            this.$timeout(() => {
                //@ts-ignore
                MathJax.typesetPromise(code()).then((e) => {
                    document.querySelectorAll('.mathjax').forEach((el) => {
                        el.classList.add('active');
                    });
                });
            });
        }
    }
    changeSizeA(row, col, onlySelf = false) {
        this.matrixA.data = this._changeSize(row, col, this.matrixA);
        this.matrixA.row = row;
        this.matrixA.col = col;
        if ((['add', 'sub', 'div'].indexOf(this.operation) != -1) && !onlySelf) {
            this.changeSizeB(row, col, true);
        }
        if ((['mul'].indexOf(this.operation) != -1) && !onlySelf) {
            if ([[row, col], [col, row]].indexOf([this.matrixB.row, this.matrixB.col]) == -1) {
                this.changeSizeB(col, this.matrixB.col, true);
            }
        }
    }
    changeSizeB(row, col, onlySelf = false) {
        this.matrixB.data = this._changeSize(row, col, this.matrixB);
        this.matrixB.row = row;
        this.matrixB.col = col;
        if ((['add', 'sub', 'div'].indexOf(this.operation) != -1) && !onlySelf) {
            this.changeSizeA(row, col, true);
        }
        if ((['mul'].indexOf(this.operation) != -1) && !onlySelf) {
            if ([[row, col], [col, row]].indexOf([this.matrixA.row, this.matrixA.col]) == -1) {
                this.changeSizeA(col, this.matrixA.col, true);
            }
        }
    }
    _changeSize(row, col, safeData) {
        this.result = undefined;
        const data = buildBlankMatrix(row, col);
        data.forEach((row, rowIndex) => {
            let oldRow;
            if (safeData.row > rowIndex) {
                oldRow = safeData.data[rowIndex];
            }
            row.forEach((col, colIndex) => {
                if ((safeData.col > colIndex) && (oldRow != undefined)) {
                    col.value = oldRow[colIndex].value;
                }
            });
        });
        return data;
    }
    clearA() {
        this.matrixA.data = buildBlankMatrix(this.matrixA.row, this.matrixA.col);
    }
    clearB() {
        this.matrixB.data = buildBlankMatrix(this.matrixB.row, this.matrixB.col);
    }
    checkBlank(data) {
        for (let row of data) {
            for (let col of row) {
                if (col.value != undefined)
                    return false;
            }
        }
        return true;
    }
    allowOperation() {
        if (['add', 'sub', 'div'].indexOf(this.operation) != -1) {
            if (this.matrixA.row != this.matrixB.row) {
                return false;
            }
            if (this.matrixA.col != this.matrixB.col) {
                return false;
            }
        }
        else if (['mul'].indexOf(this.operation) != -1) {
            if (this.matrixA.col != this.matrixB.row) {
                return false;
            }
        }
        else if (['div'].indexOf(this.operation) != -1) {
            if (this.matrixA.col != this.matrixB.col) {
                return false;
            }
        }
        return true;
    }
    fillA(value) {
        this.matrixA.data = this._fill(this.matrixA.row, this.matrixA.col, value);
    }
    fillB(value) {
        this.matrixB.data = this._fill(this.matrixB.row, this.matrixB.col, value);
    }
    switchAB() {
        const matrixA = ng.copy(this.matrixA);
        this.matrixA = ng.copy(this.matrixB);
        this.matrixB = matrixA;
    }
    randomA() {
        this.matrixA.data = this._fill(this.matrixA.row, this.matrixA.col, () => random(0, 999));
    }
    randomB() {
        this.matrixB.data = this._fill(this.matrixB.row, this.matrixB.col, () => random(0, 999));
    }
    _fill(row, col, value) {
        return buildBlankMatrix(row, col).map((row) => row.map((col) => {
            col.value = typeof value === 'function' ? value() : value;
            return col;
        }));
    }
    copyToA() {
        if (this.result.matrix) {
            const data = this.result.data.map((row) => [].slice.call(row).map((cel) => {
                return { value: cel };
            }));
            this.changeSizeA(data.length, data[0].length);
            this.matrixA.data = data;
        }
    }
    copyToB() {
        if (this.result.matrix) {
            const data = this.result.data.map((row) => [].slice.call(row).map((cel) => {
                return { value: cel };
            }));
            this.changeSizeB(data.length, data[0].length);
            this.matrixB.data = data;
        }
    }
}
MatrixcalculatorCtrl.$inject = ['$scope', '$timeout', '$filter', 'ConfigService'];
const appModule = ng.module('app');
appModule.component('gameMatrixcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: MatrixcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('matrixcalculator/');
    }]);
